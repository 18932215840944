import React from 'react';
import "../styles/gst.css";
import Layout from "../components/Layout";
import ContentForm from "../components/GSTTemp/ContentForm";
import {
  Seo
} from '../components/seo';
const PrivacyPolicy = () => {
  const privacyData = {
    id: "procedure",
    mt_div: "mt-5",
    mt_img: "mt-5",
    header: "Privacy Policy",
    content: [
      `According to the Information Technology Act of 2000 and any applicable rules, as well as the provisions of 
        different statutes that have been updated to reflect the Information Technology Act 's amendments, this document 
        is an electronic record.There are neither physical nor digital signatures necessary
        for this electronic record
        because it is produced by a computer system.\nThis document is published in accordance with the requirements of Rule 3(1)(a) of the Information Technology(Intermediary Guidelines and Digital Media Ethics Code) Rules, 2021 and Rule 4 of the(Indian) Information
        Technology(Reasonable Security Practises and Procedures and Sensitive Personal data or Information) Rules,
          2011, which were created under the(Indian) Information Technology Act, 2000(as amended from time to time).
        `,
    ],
    body: [{
        head: "1. Customer data privacy",
        points: `The value of Your financial information is recognised by TODAYFILINGS, and all information within its
           purview is treated as absolutely secret.TODAYFILINGS has never disclosed or shared any such private
           information with an outside organisation and will not do so in the future.
           `,
        icon: false,
      },
      {
        points: `The personal information of its users is not disclosed by TODAYFILINGS to any other business, group, 
          or person.You can put your trust in us because everything is kept safe and private with us, and we won 't 
          share any of the personal information you give us with anybody
          else unless specifically permitted by this
          privacy statement.
          `,
        icon: false,
      },
      {
        points: `The Information Technology Act 2000 and the Information Technology (Reasonable security practises and
               procedures and sensitive personal data or information) Rules, 2011 are two pieces of Indian law that apply to us.
               `,
        icon: false,
      },
      {
        head: "2. The Amount of TODAYFILING'S Liability",
        points: `Any security lapses or the activities of any third parties who get Your Personal Information are not 
          TODAYFILING 's fault. Your privacy and personal information are important to TODAYFILING. Nevertheless, 
          TODAYFILING shall not be liable
          for any loss, damage, or misuse of Your Personal Information
          if such loss,
          damage, or misuse is caused by a Force Majeure Event(as defined below), regardless of anything
          else stated in
            this Privacy Policy or elsewhere.
          `,
        icon: false,
      },
      {
        points: `Any event that is beyond TODAYFILINGS' reasonable control is referred to as a "Force Majeure Event"
           and may include, without limitation, sabotage, fire, flood, explosion, acts of God, inevitable accidents,
             civil unrest, strikes or other forms of industrial action, riots, insurrection, war, acts of government,
             computer hacking, system hacking, unauthorised access to computer data and storage devices, computer crashes,
             breach of security and encryption, etc.TODAYFILINGS will make a reasonable effort to safeguard that
             information against loss, unauthorised access, modification, and disclosure in addition to misuse and
           unauthorised use.
           `,
        icon: false,
      },
      {
        head: "3.Data that TODAYFILINGS gathers and makes use of",
        points: `You are not needed to give us any personal information when you visit our platform unless you wish to 
          use our online services, subscribe to our blog or free newsletter, or use one of the other services listed
          below.By using Our online services, you hereby provide your consent
          for your information(including sensitive personal information) to be collected, used, stored, disclosed, and otherwise processed on the Platform in
            accordance with this Privacy Policy.If you give us any personal information— including sensitive personal
          information— about another person, you certify that you have that person 's permission to do so and that we
          may use the personal information you give us in accordance with this privacy statement.
          `,
        icon: true,
      },
      {
        points: `Blog and Newsletters: We collect Your email address when You sign up for Our blog or newsletters so 
          that We can send You the newsletters by email.You can choose to receive fewer communications from
          TODAYFILINGS at any time by clicking the "Unsubscribe"
          link or by adhering to the instructions in each
          promotional or marketing email message.
          `,
        icon: true,
      },
      {
        points: `Using a service for filing taxes and other relevant services- When using TODAYFILINGS's services for 
          filing a
          return, receiving advice, or receiving any other service, you must provide certain information,
          including Your name, address, phone number, email address, permanent account number("PAN"), bank account number,
            IFSC Code, income and expense data, asset and income details, vouchers, bank statements, form 16 / form 16 A, tax
          payment challans, debit and credit account numbers, and its expiration, as well as any other information necessary
          to prepare the
          return.This data is gathered in order to prepare and submit your income tax
          return.
          `,
        icon: true,
      },
      {
        points: `Registration: You must register on the website and supply your name, contact information, email address, 
          and password in order to use our tax preparation and income tax
          return filing services.In order to communicate
          ITR acknowledgements, customer refund status, any additional notices, your job status, and other details that may
          be necessary in the future
          for various urgent reasons, TODAYFILINGS requests Your email address and contact
          information when you register.This data is gathered in order to provide tax preparation services, identify you
          personally, contact you
          for customer support needs, give you tax updates and newsletters, and engage in marketing
          conversations with you both online and offline.
          `,
        icon: true,
      },
      {
        points: `Using the typical information that browsers transmit to each website you use, such as IP address, 
          browser type, and previously visited web pages, TODAYFILINGS may gather information about the viewed pages,
          links, and actions made.TODAYFILINGS exclusively uses the data to develop and improve the
          return filing
          process.
          `,
        icon: true,
      },
      {
        points: `We keep track of the User's tax filing process as part of our commitment to quality and for their 
          better experience.This activity is only intended to help us better understand user behaviour so that we can
          make the product and the process simpler.
          `,
        icon: true,
      },
      {
        points: `The Platform will ask You for Personal Information, such as Your name, email address, birth date, 
          pin code, occupation, and industry, when You register with TODAYFILINGS.You are no longer anonymous to
          TODAYFILINGS after you register with them and log in to their services.
          `,
        icon: true,
      },
      {
        points: `Your Personal Information will only be gathered, stored, used, and shared by TODAYFILINGS in 
          accordance with this Privacy Policy and applicable Indian laws.You might be requested to submit the personal
          information of people who are either your dependents or people whose income you are accountable
          for when
          filing a tax
          return online, in addition to your own personal information.
          `,
        icon: true,
      },
      {
        points: `Only data that you supply is kept by TODAYFILINGS. TODAYFILINGS does not include information 
          from other firms to what you provide.The only exception to this rule is
          if your employer has purchased the
          services or goods in bulk
          for use by its employees, in which
          case We may upload data supplied by your employer
          to make that possible.
          `,
        icon: true,
      },
      {
        points: `To help tailor offers and tools to Your specific interests, TODAYFILINGS may gather non-identifiable 
          information from You when You use calculators and other tools on the Platform that may or may not require
          registration.Unless We expressly notify You that We are doing so at the time You give such data, TODAYFILINGS
          will not record or track any data You enter into standalone tools and calculators in any way that identifies
          You personally.
          `,
        icon: true,
      },
      {
        points: `It is assumed that you have read this privacy statement and have given permission for TODAYFILINGS to
           access the information needed
           for the service you are seeking
           if you use any other TODAYFILINGS services that
           require TODAYFILINGS to access Your form 16 in either physical or electronic form that you provide to
           TODAYFILINGS either physically through post, courier, or any other mode, or by email.
           `,
        icon: true,
      },
      {
        points: `You agree and give TODAYFILINGS permission to save your user name and password for the income tax 
          platform.As a result, TODAYFILINGS may use your account
          for research and to provide you with services.
          `,
        icon: true,
      },
      {
        points: `TODAYFILINGS uses Your information for the general purposes listed below: to tailor the advertising 
          and content you see, respond to your product and service requests, enhance Our services, get in touch with
          you, carry out research, and produce anonymous reporting
          for internal and external clients.During the time
          that TODAYFILINGS keeps this information on file, TODAYFILINGS maintains the right to utilise the personal
          information in any investigation or legal proceeding involving fraud arising from such transactions.
          `,
        icon: true,
      },
      {
        points: `After an investigation has been opened under any legislation, TODAYFILINGS has the right to follow up
           on any information that has been removed from the platform.
           `,
        icon: true,
      },
      {
        points: `Without the users' express permission, TODAYFILINGS will never sell or rent the personal information 
          of Users to other parties.
          `,
        icon: true,
      },
      {
        points: `TODAYFILINGS uses personal data to maintain the Platform's reliability.`,
        icon: true,
      },
      {
        points: ` To help Us create new products and services, We analyse Your Personal Information to find out how 
          TODAYFILINGS products and services are used.Subject to this Privacy Policy, we keep copies of Your completed
          and filed tax returns in accordance with TODAYFILINGS ' records and information management policy.`,
        icon: true,
      },
      {
        head: "4. Transfer and Disclosure of Personal Information",
        points: ` To help Us create new products and services, We analyse Your Personal Information to find out how 
          TODAYFILINGS products and services are used.Subject to this Privacy Policy, we keep copies of Your completed
          and filed tax returns in accordance with TODAYFILINGS ' records and information management policy.`,
        icon: true,
      }, {
        points: `TODAYFILINGS will never give, sell, trade, lease, or rent any of your personal information to anybody 
          for any reason.Additionally, we might give trustworthy third - party suppliers aggregate statistics about Our
          Customers, sales, traffic patterns, and associated website data.However, these statistics in no way comprise
          Your personally identifying information.
          `,
        icon: true,
      }, {
        points: `All TODAYFILINGS staff have been chosen in accordance with Our stringent security standards and 
          procedures and are obligated to maintain confidentiality.If any of these requirements are not met,
            TODAYFILINGS personnel will face severe disciplinary action, which may include termination, civil liability,
            and criminal prosecution.
            `,
        icon: true,
      }, {
        points: `In order to provide services and/or enable Us to provide Services to You, TODAYFILINGS may also 
          engage third - party suppliers, individuals, or service providers, such as operating the Platform 's payment gateway 
          functionality or couriering documents to You on Our behalf.According to the specific agreements we have with them
          and the relevant regulations, these third parties must uphold the confidentiality of the information we give them.
          `,
        icon: true,
      }, {
        points: `We may share sensitive Personal Information or Personal Information for the purpose of confirming 
          identity with government or law enforcement agencies, as required by law, or
          for the prevention, detection,
          investigation, including of cyber incidents, prosecution, and punishment of offences, or to protect the
          interests of TODAYFILINGS and / or any specific User(s), but we will only do so in accordance with applicable
            privacy laws.You give Us permission to share Your Personal Information in these circumstances.Any Personal
          Information or Sensitive Personal Information may be provided to a third party pursuant to a court order,
          regardless of anything
          else stated in this Privacy Policy.
          `,
        icon: true,
      }, {
        points: `In the event that it is required to carry out a valid contract between TODAYFILINGS and the User, 
          or in the event that the User has given their consent, TODAYFILINGS may transfer Personal Information to any
          other body corporate or individual.
          `,
        icon: true,
      }, {
        points: `TODAYFILINGS will make sure that the entity or individual to whom the data transfer occurs upholds 
          the same standard of data protection as required by applicable legislation.
          `,
        icon: true,
      }, {
        points: `We might divulge Personal Information to affiliates and organisations in Ourgroup. As a result of 
          such sharing, these group companies and affiliates may market to You unless You specifically opt - out by
          contacting Us in writing.
          `,
        icon: true,
      }, {
        points: `If We plan to merge with, be acquired by, reorganise, amalgamate, or otherwise restructure Our 
          business, We and Our group companies and affiliates may share or sell some or all of Your Personal Information
          to that other business entity.In the event that such a transaction takes place, the other business entity(or the new combined firm) shall be obligated to abide by the conditions set forth in this Privacy Policy
          with regard to Your Personal Information.
          `,
        icon: true,
      }, {
        head: "5. Confidentiality and Information Security ",
        points: `Using industry-recognized security precautions together with carefully crafted security processes and practises,
          TODAYFILINGS works to protect Your Personal Information, including Your tax
          return information, from loss, abuse,
            or unauthorised change.All tax
          return information is kept secure both electronically and procedurally by
          TODAYFILINGS, which also regularly reviews its security protocols using internal and external resources.
          TODAYFILINGS supports encryption of any information transferred to Us whenever transferring sensitive information,
            such as tax
            return or credit card information, is necessary.The International Standard ISO / IEC 27001 on "Information Technology Security Techniques Information Security Management System-Requirements"
            guides our
            efforts to safeguard the security of your information.
            `,
        icon: false,
      }, {
        head: "6. ​Customer Testimonials and Comments",
        points: `On Our Platform, we publish user reviews and comments that can include Personal Information. We only 
          publish this information when we receive it from the client via mail or on Our social media accounts, and by
          agreeing to this, you authorise us to do so.
          `,
        icon: false,
      }, {
        head: "7. Cookies",
        points: `Many of Our web sites use "cookies" and client IDs to improve Your experience with Our Platform. 
          Cookies are text files that we keep in the browser on Your computer to remember your preferences.Unless You
          choose to give Us Your email address or other Personal Information, cookies by themselves do not divulge Your
          Personal Information to Us.However,
            if You choose to provide Us with personally identifiable information,
          this data may be connected to cookie data.
          `,
        icon: false,
      }, {
        head: "8. ​Withdrawal of Consent",
        points: `You have the right to revoke any consent you have given to us in accordance with this Policy at any 
          time.Written notice of this withdrawal of consent must be given to support @todayfilings.com.In this
          case,
          We will either delete Your Personal Information(including any sensitive Personal Information) or de - identify
          it so that it is anonymous and cannot be associated with You.
          `,
        icon: false,
      }, {
        head: "9. Access to Your Information",
        points: `Your information is accessible in the Platform's "My Dashboard" area. You can send a written request 
          to asking
          for access to information you gave but is not accessible through the
          My Dashboard feature.
          `,
        icon: false,
      },
      {
        head: "10. Others",
        points: `
          Links to other websites may be available on our website.The content and actions of those other
          parties and their platforms are not under our control or responsibility.By signing this document, you
          acknowledge that you understand and accept that this privacy statement does not apply to any other platforms,
          which are governed by any applicable privacy and other rules.
          `,
        icon: false,
      },
      {
        points: `
          We provide community forums, blogs, and message boards that are open to the public.You hereby
          acknowledge and agree that any information that You directly provide through Our public message boards, blogs,
          or forums may be collected and utilised by others.If You so request in writing to Us, We shall amend or
          remove any information You have posted on the Websites.
          `,
        icon: false,
      },
      {
        points: `
          By signing this document, you confirm that you will give information on taxes paid, income, and other
          pertinent factors as requested by Form 26. You hereby undertake to tell the TODAYFILINGS of any inconsistency.
          `,
        icon: false,
      }, {
        points: `
          In order
          for Us to file the tax correctly, it is your obligation to share the information on Form
          26 AS.If there were any errors, and
          if any later claims were made that were not the fault of TODAYFILINGS,
            it is hereby made clear that TODAYFILINGS shall not be held liable in any way.
          `,
        icon: false,
      }, {
        head: "11. Changes to this Privacy Policy",
        points: `
          There may be periodic changes to this privacy statement.Without your express permission, TODAYFILINGS will
          not limit your rights under this Privacy Policy.Any time TODAYFILINGS makes changes to the Privacy Policy, We 'll post
          those changes on this page and give you a clear warning(such as a pop - up window, email, or similar notification).The
          new Privacy Policy will go into effect as soon as You
          continue to use the Platform, use the Services, or initiate a payment
          transaction through the Platform.Your sole and only recourse is to stop using the Platform and the Service
          if you disagree with
          any such modification.After updates are posted,
            if you use the platform again, it will be assumed that you accept and consent to
          those changes.
          `,
        icon: false,
      },

      {
        head: "12. Contacting Us",
        points: `
          Please get in touch with Mr.Gangadhar, our grievance redressal officer,
            if you have any complaints
          regarding the Privacy Policy or other issues.Contact Information: 9585055661. Message me at
          support @todayfilings.com.We will make sure to acknowledge and address Your complaint within the allotted time
          frames, in accordance with the Information Technology Act, 2000 and the Rules.Please get in touch with us at
          the following address
          if you have any questions about this Privacy Statement, this Platform 's policies, 
          or your interactions with it.
          `,
        icon: false,
      }, {
        points: `
          No B1 Vishnu castle MARUTHI nagar 3 rd cross dharga
          Hosur - 635126 - Tamilnadu `,
        icon: false,
      }, {
        points: `
          Phone: 9585055661 `,
        icon: false,
      }, {
        points: `
          Email: support @taxfilings.com `,
        icon: false,
      },
    ],
  };

  return (
    <div>
      <Seo
        title="Privacy Policy"
        keywords="Best Privacy Policy in Hosur,Best Privacy Policy near Bangalore,Privacy Policy in Chennai"
        description="TODAYFILINGS is the one of the best online Privacy Policy service in Hosur"
        name="TODAYFILINGS"
        type="Privacy Policy"
        author="TODAYFILINGS"
      />
      <Layout>
        <main id="main">
          <ContentForm CFSection_data={privacyData} />
        </main>
      </Layout>
    </div>
  );
}

export default PrivacyPolicy;